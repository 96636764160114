.venta-card {
  width: 100%;
  margin: 0 10px;
}

.ventas-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.venta-card {
  background-color: #fff;
  color: #000;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.venta-id {
  color: green;
}
