/* Estilos personalizados para las Cards */

.custom-card {
  background-color: #f8f9fa; /* Color de fondo */
  border: 1px solid #dee2e6; /* Color del borde */
  border-radius: 10px; /* Bordes redondeados */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra */
  transition: box-shadow 0.3s ease-in-out; /* Transición suave de la sombra */

  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Efecto de sombra al pasar el ratón */
  }

  .card-body {
    padding: 20px; /* Espaciado interno del cuerpo de la Card */
  }

  .card-title {
    color: #007bff; /* Color del título */
    font-size: 1.5rem; /* Tamaño del título */
    margin-bottom: 15px; /* Margen inferior del título */
  }

  table {
    width: 100%;
    margin-bottom: 0; /* Eliminar el margen inferior de la tabla */
  }

  tbody tr {
    border-bottom: 1px solid #dee2e6; /* Línea divisoria entre filas */
  }

  td {
    padding: 10px; /* Espaciado interno de las celdas */
  }
}
